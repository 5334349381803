
// @ts-nocheck
import {Component, Watch} from 'vue-property-decorator'
import {DataTableHeader} from "vuetify";
import UserEditor from "@/components/user/UserEditor.vue";
import User, {UserWrapper} from "@/assets/Entities/User";
import GenericListComponent, {Filter} from "@/components/generic/GenericListComponent.vue";
import {TargetType} from "@/assets/constants/Enums";
import {SchoolURLParams} from "@/assets/service/Endpoints";

@Component({
  components: {UserEditor}
})
export default class UserList extends GenericListComponent<User, UserWrapper> {
  created() {
    this.loadItems();
  }

  headers: DataTableHeader[] = [
    {
      text: this.$t('User.List.Table.Header1').toString(),
      value: 'login',
      width: '30%'
    },
    {
      text: this.$t('User.List.Table.Header2').toString(),
      value: 'name',
      width: '30%'
    },
    {
      text: this.$t('User.List.Table.Header3').toString(),
      value: 'immunLocalized',
      width: '30%'
    },
    {
      text: this.$t('User.List.Table.Header4').toString(),
      value: 'action',
      sortable: false,
      width: '10%'
    }];

  // @ts-ignore
  get filters(): Filter[] {
    return [];
  }

  @Watch('items')
  watchItems() {
    for (let i = 0; i < this.items.length; i++) {
      // get in User won't work...
      if (this.items[i].last_questions) {
        this.items[i].lastQuestionsLocalized = this.$d(new Date(this.items[i].last_questions)).toString()
      }
      if (this.items[i].immun) {
        this.items[i].immunLocalized = this.$d(new Date(this.items[i].immun)).toString()
      }
    }
  }

  get params(): SchoolURLParams {
    return {
      type: this.checkbox2Val ? TargetType.GUEST_LIST : TargetType.USER,
      client: this.$route.params.client
    }
  };

  showInactive: boolean = false;

  checkbox2 = true;

  checkBoxLabel: string = 'User.List.InactiveUsers';
  checkBoxLabel2: string = 'User.List.Guests';

  title = "User.List.Title";
  addButtonTitle = "User.List.AddUser";
  addButtonIcon = "mdi-account-plus";
  elementActionIcon = "mdi-account-edit";
  dialog = "user-editor";
  rowsPerPageText: string = 'User.RowsPerPage';
}
